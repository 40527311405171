exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-digitals-page-js": () => import("./../../../src/templates/digitals-page.js" /* webpackChunkName: "component---src-templates-digitals-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-modelling-page-js": () => import("./../../../src/templates/modelling-page.js" /* webpackChunkName: "component---src-templates-modelling-page-js" */),
  "component---src-templates-styling-page-js": () => import("./../../../src/templates/styling-page.js" /* webpackChunkName: "component---src-templates-styling-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-work-post-js": () => import("./../../../src/templates/work-post.js" /* webpackChunkName: "component---src-templates-work-post-js" */)
}

